<template>
    <div>
        <div v-if="case_show" class="popup" @click.self="handle">
            <div class="popup-content">
                <div class="poup_content_title">广告说明</div>
                <div v-html="scope.row.content">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // data_list: {},
        scope: Object,
    },
    mounted() {
    },
    data() {
        return {
            case_show: false,

        }
    },
    methods: {
        handle() {
            this.case_show = !this.case_show;
        },

    },
}
</script>

<style lang=scss scoped>
@import '@/scss/poup';

.popup-content {
    background: #fff;
    height: 80vh;
    overflow-y: auto;
    position: relative;
}

.carousel_img {
    width: 100%;
    height: 600px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

}
.poup_content_title{
    padding: 10px 10px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(16, 140, 221, 1);
    font-size: 14px;
    border-radius: 10px 10px 0 0;
    color: #fff;
}
::v-deep .el-carousel__container {}

::v-deep .popup-content {
    width: 40%;
    padding: 60px 20px 40px 20px;
}

.popup {
    z-index: 9999;
}
</style>