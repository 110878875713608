<template>
  <div>
    <div v-if="column_type === 'text'">
      <span style="color: rgba(51, 51, 51, 1);">{{ scope.row[prop] }}</span>
    </div>
    <div v-if="column_type === 'slect_money'">
      <el-dropdown trigger="click" @command="(command) => { select_item(scope.$index, command, prop) }">
        <el-button type="primary" size="mini">
          {{ scope.row.type != '' ? scope.row[prop][scope.row.type] : scope.row[prop][0] }}元/{{
            cycle_list[scope.row.type]
          }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="index" v-show="item != 0" v-for="(item, index) in scope.row[prop]">{{
              item
            }}元/{{
              cycle_list[index]
            }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-if="column_type === 'button_money'">
      <button :class="scope.row.check == true ? 'priceActive' : 'price'"
              @click.stop="handle_price_click(scope.$index)">￥{{
          scope.row[prop]
        }}
      </button>
    </div>
    <div v-if="column_type === 'input_number'">
      <el-input-number size="mini" :min="1" v-model="scope.row.cycle" @change="handle_cycle"></el-input-number>
    </div>
    <div v-if="column_type === 'remark'">
      <td_remark :text="scope.row.remark"/>
    </div>
    <div class="controls flex" :style="{'justify-content':user_info?'space-between':'center'}" v-if="column_type === 'controls'">
      <div>
        <p @click="case_poup" v-if="scope.case_url">图片案例</p>
        <p @click="instructions_poup">广告说明</p>
      </div>
      <!--      {{ $user_info }}-->
      <span v-if="scope.row.is_collect == 0 && user_info" @click="collect">收藏</span>
      <span style="color: #108CDD;" v-if="scope.row.is_collect == 1 && user_info"
            @click="collect_close">取消收藏</span>
    </div>
    <placard_media_case ref="placard_media_case" :list="scope.case_url"/>
    <placard_media_instructions ref="placard_media_instructions" :scope="scope"/>
  </div>

</template>

<script>
import {mapMutations} from 'vuex'
import td_remark from '@/components/td_remark'
import placard_media_case from '@/components/placard_media_case'
import placard_media_instructions from '@/components/placard_media_instructions'

export default {
  components: {
    td_remark,
    placard_media_case,
    placard_media_instructions
  },
  computed: {
    ...mapMutations(['SET_CAR_LIST']),
  },
  mounted() {
    // let user_info = JSON.parse(localStorage.getItem('user_info'))
    // if(user_info){
    //   this.user_info = user_info
    // }
    // console.log(Object.keys(this.user_info))
    this.user_info = localStorage.getItem('user_info')

  },
  props: {
    /* column_type类型定义:
        text:纯文本
        slect_money:金额选择器
        input_number:计数器
        button_money:金额按钮
        remark:备注
        controls:操作
        */
    column_type: String,
    width: {
      type: Number,
      default: 100
    },
    prop: {
      type: String,
      default: ''
    },
    scope: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      cycle: 1,
      cycle_list: ['天', '周', '月', '次', '期'],
      user_info: {}
    }
  },
  methods: {
    handle_price_click(index) {
      this.$emit('handle_price_click', index)
      // console.log(this.scope.row, 'scope');
      this.select_item(index, this.scope.row.type != '' ? this.scope.row.type : 0, 'member_guanggao_price')
      this.$store.commit('SET_CAR_LIST', this.scope.row)
    },
    case_poup() {
      this.$refs.placard_media_case.handle()
    },
    handle_cycle(currentValue) {
      this.$emit('handle_cycle', currentValue, this.scope.$index)
      this.select_item(this.scope.$index, this.scope.row.type != '' ? this.scope.row.type : 0, 'member_guanggao_price')
      this.$store.commit('SET_CAR_LIST', this.scope.row)
    },
    instructions_poup() {
      this.$refs.placard_media_instructions.handle()
    },
    select_item(index, command, prop) {
      let select_data = {
        index: index,
        command: command,
        prop: prop
      }
      this.$emit('select_item', select_data)
      this.$store.commit('SET_CAR_LIST', this.scope.row)
    },
    collect() {
      this.curlGet('/api/guanggao_info/collect', {
        gid: this.scope.row.id
      }).then(res => {
        if (res.data.code) {
          this.$emit('collect_status', 1, this.scope.$index)
          this.$message({
            type: 'success',
            message: '收藏成功!'
          })
        }
      })
    },
    collect_close() {
      this.$confirm('是否取消收藏?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.curlGet('/api/guanggao_info/collect', {
          gid: this.scope.row.id
        }).then(res => {
          if (res.data.code) {
            this.$emit('collect_status', 0, this.scope.$index)
            this.$message({
              type: 'success',
              message: '取消收藏成功!'
            })
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    }
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/placard_media';
@import '@/scss/poup';
@import '@/scss/price';
</style>