<template>
    <div>
        <div v-if="case_show" class="popup" @click.self="handle">
            <div class="popup-content">
                <el-carousel ref="carouselRef" :interval="3000" height="600" arrow="always"
                    indicator-position="none" :loop="true">
                    <el-carousel-item v-for="item in list" :key="item">
                        <div class="carousel_img" :style="{ backgroundImage: 'url(' + item + ')' }">
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // data_list: {},
        list: Array,
        default:()=>[]
    },
    mounted() {
    },
    data() {
        return {
            case_show: false,
        }
    },
    methods: {
        handle() {
            this.case_show = !this.case_show;
        },

    },
}
</script>

<style lang=scss scoped>
@import '@/scss/poup';

.popup-content {
    background: none;
}

.carousel_img {
    width: 100%;
    height: 600px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

}

::v-deep .el-carousel__container {}

::v-deep .popup-content {
    width: 40%;
    padding: 40px;
}

.popup {
    z-index: 9999;
}
</style>